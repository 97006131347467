.home{
    flex: 4;
}

.homeWidgets{
    display: flex;
    margin: 10px;
}
.datePicker{
    padding:16px;
    font-size: 20px;
}
.dashCustomer{
    padding-left:16px;
}

input[type="date"], 
input[type="time"] {
    width: 150px;
    height: 30px;
    margin-right: 10px;
    padding-left:5px;
    border: 1px solid;
    
}
.button_custom{
    border: none;
    border-radius: 10px;
    padding: 5px 15px;
    background-color: #EE8253;
    color: white;
    cursor: pointer;
    margin:0px;
    margin-top:10px;
    width:100px;
  }
