/* header {
  grid-area: header;
  padding: 5px 0px 5px 0px;
  align-items: center;
  justify-content: center;
  background-color: white;
  border: 0px;
  height: 100px;
  position: fixed;
   margin-left: 200px;
  width: 87%;
  z-index: 1;
} */

header {
  grid-area: header;
}

article {
  margin-top: 65px;
  grid-area: content;
  background-color: #f9f9f9;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}
aside {
  grid-area: sidebar;
  background-image: "linear-gradient( to right, #FF3131, #FF914C )";
  height: 100%;
}
footer {
  padding: 20px;
  grid-area: footer;
  background-color: #f6deca;
}
.wrapper {
  background-color: #f9f9f9;
  display: grid;
  grid-template-columns: 0.3fr 3fr;
  grid-template-areas:
    "sidebar  header"
    "sidebar content"
    "footer  footer";
}
.custom_title {
  width: 100%;
  padding: 0px 40px 0px 40px;
  font-size: 24;
  font-weight: 700;
  background-color: white;
}
