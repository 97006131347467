.image-container {
  position: relative;
  display: inline-block; /* Pode ser block ou inline-block, depende do layout */
}
.imgSize {
  width: 150px;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
  margin-right: 3px;
  pointer-events: none;
  position: relative;
}
.overlay {
  height: 20px;
  width: 60px;
  position: absolute;
  left: 55%; /* Ajuste conforme necessário */
  bottom: 5%; /* Ajuste conforme necessário */
  background-color: white;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  pointer-events: all; /* Permite interações com a div de sobreposição */
  font-size: 12px;
}
