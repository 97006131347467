img.custom_banner {
  width: 100%;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
}
.div_banner {
  max-width: 1216px;
  max-height: 256px;
  height: auto;
  width: 100%;
  padding: 20px;
}
.div_home_Container {
  margin-top: 10px;
  margin-right: 20px;
  position: relative;
  cursor: pointer;
}
.datePicker {
  font-size: 14px;
  align-items: right;
  justify-content: right;
  text-align: right;
  display: inline;
  float: right;
  padding: 0px 30px 0px 0px !important;
}
.column {
  display: inline;
  float: right;
  font-size: 14px;
  font-family: "Manrope";
  font-weight: 700;
  padding: 5.5px;
  margin-right: 10px;
  color: #666666;
  vertical-align: middle;
}
.labelPeriodo {
  font-size: 14px;
  font-family: "Manrope";
  font-weight: 700;
  color: #666666;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

input[type="date"],
input[type="time"] {
  width: 100px !important;
  height: 30px;
  margin-right: 5px;
  padding-left: 5px;
  border: 0.5px solid;
  border-color: #999999;
  border-radius: 3px;
  font-size: 14px;
  font-family: "Manrope";
  color: #666666;
}
input[type="radio"] {
  accent-color: #ff3131;
}

.div_home_custom {
  display: grid;
  padding: 40px;
  border-radius: 24px;
  border: 1px solid var(--opacity-8, rgba(0, 0, 0, 0.08));
  background: var(--Greys-White, #fff);
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
}

.div_custom_Quadro {
  padding: 5px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  width: 90%;
}
.tableSpace {
  border-collapse: separate;
  border-spacing: 40px;
  border: 0px;
}
.tableSpaceTab {
  border-collapse: separate;
  border-spacing: 20px 40px;
  border: 0px;
}
.tableSpaceTab2 {
  border-collapse: separate;
  border-spacing: 20px 20px;
  border: 0px;
}

.table {
  border: 0px;
  justify-content: left;
  align-items: left;
  width: 100%;
}
.left {
  font-size: 16px;
  font-weight: 700;
  color: #999999;
  line-height: 24px;
}
.right {
  font-size: 24px;
  font-weight: 700;
  color: #ee8253;
  line-height: 32px;
  text-align: right;
  justify-content: right;
}
.right_black {
  font-size: 24px;
  font-weight: 700;
  color: black;
  line-height: 32px;
  text-align: right;
  justify-content: right;
}
.details {
  font-size: 16px;
  font-weight: 700;
  color: #ee8253;
  margin-top: 32px;
  justify-self: center;
  text-decoration: none;
}
.labelTitle {
  text-align: left;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: black;
}
.labelMsgData {
  text-align: left;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: #999999;
}
.labelMsgDataAtivos {
  text-align: left;
  font-size: 10px;
  font-weight: 700;
  color: #999999;
  padding: 0px 5px 0px 5px;
}
.labelMsgDataAtivosw {
  text-align: left;
  font-size: 10px;
  font-weight: 700;
  color: #999999;
  padding: 0px 0px 0px 0px;
  margin: 0px;
}
.labelDesc {
  text-align: left;
  padding: 0px 0px 0px 5px;
  font-size: 10px;
  font-weight: 700;
  margin-top: 0px;
  color: #999999;
}

.icon {
  padding: 16px;
  width: 24px;
  height: 24px;
}
.table_painel {
  border-collapse: collapse;
  width: 100%;
  border: 0px;
  margin-bottom: 16px;
}

.status_painel {
  text-align: left;
  padding: 0px 5px 0px 5px;
  font-size: 16px;
  font-weight: 700;
  margin: 0px;
  color: #999999;
  line-height: 24px;
}
.status_painelw {
  text-align: left;
  padding: 0px 0px 0px 5px;
  font-size: 16px;
  font-weight: 700;
  margin: 0px;
  color: #999999;
  line-height: 20px;
}
.labelValue_painel {
  text-align: left;
  padding: 3px 5px 5px 5px;
  font-size: 16px;
  font-weight: 700;
}
.labelValue_painel_black {
  text-align: left;
  padding: 3px 5px 5px 5px;
  font-size: 24px;
  font-weight: 700;
  color: black;
}

.labelValue_painel_black_vlr {
  text-align: left;
  padding: 3px 5px 5px 5px;
  font-size: 20px;
  font-weight: 700;
  color: black;
}
.icon_painel {
  color: #999999;
  padding: 5px 5px 0px 5px;
  width: 20px;
  height: 20px;
}
.chart {
  margin: 0px;
  padding: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.chartTitle {
  margin-bottom: 20px;
}

table.tCharts {
  width: 100%;
  border: 0px;
  padding: 0px;
}
td.tCharts {
  border: 0px;
  padding: 0px;
}
.divTab {
  position: fixed;
  overflow: hidden;
  padding-left: 40px;
  justify-content: left;
  border-top: 0px solid #666666;
  border-bottom: 0px solid #666666;
}

.divRodape {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  color: #ee8253;
}

.divFixa {
  top: 0;
  left: 200px;
  position: fixed;
  width: 100%;
  background-color: white;
  z-index: 999; /* Garante que a div fixa esteja sempre acima do conteúdo rolável */
}
