.invoice-box {
  padding: 15px;
  font-size: 12px;
  max-width: 800px;
  font-family: "Manrope", sans-serif;
  /* margin: auto;
  border: 1px solid #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

  line-height: 24px;
  
  color: #555;
  */
}
.heading {
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-weight: bold;
}
.items {
  font-size: 10px;
  font-weight: 500;
}
.negrito {
  font-weight: 700;
  font-size: 12px;
}
.spanData {
  font-weight: 500;
  font-size: 10px;
}
.labelHead {
  text-align: center;
  color: black;
}
.logoimg {
  width: 150px;
  text-align: center;
}
.divlogoimg {
  text-align: center;
}
.labelHash {
  text-align: center;
  font-size: 8px;
}
