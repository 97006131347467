.input{
    border: none;
    border-radius: 10px;
    padding: 5px 15px;
    background-color: #EE8253;
    color: white;
    cursor: pointer;
    margin-right: 20px;
    width:180px;
}


input[type=file]::file-selector-button {
    margin-right: 5px;
    margin-left: 15px;
    border: none;
    background: #EE8253;
    padding: 5px 15px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background .2s ease-in-out;
  }
  
  input[type=file]::file-selector-button:hover {
    background: #EE8253;
  }