.product {
  flex: 4;
  padding: 20px;
}

.productTitleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.productAddButton {
  width: 80px;
  border: none;
  padding: 5px;
  background-color: teal;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

.productTop {
  display: flex;
}

.productTopLeft {
  flex: 1;
  width: 80%;
}
.productTopRight {
  flex: 1;
}

.productTopRight {
  padding: 20px;
  margin: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.productInfoImg {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.productInfoTop {
  display: flex;
  align-items: center;
}

.productName {
  font-weight: 600;
}

.productInfoBottom {
  margin-top: 10px;
}

.productInfoItem {
  width: 150px;
  display: flex;
  justify-content: space-between;
}

.productInfoValue {
  font-weight: 300;
}

.productBottom {
  padding: 20px;
  margin: 20px;
  -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
}

.productForm {
  display: flex;
  justify-content: space-between;
}

.productFormLeft {
  display: flex;
  flex-direction: column;
}

.productFormLeft > label {
  margin-bottom: 10px;
  color: gray;
}

.productFormLeft > input {
  margin-bottom: 10px;
  border: none;
  padding: 5px;
  border-bottom: 1px solid gray;
}

.productFormLeft > select {
  margin-bottom: 10px;
}

.productUploadImg {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  object-fit: cover;
  margin-right: 20px;
}

.productFormRight {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.productUpload {
  display: flex;
  align-items: center;
}

.productButton {
  border: none;
  padding: 5px;
  border-radius: 5px;
  background-color: darkblue;
  color: white;
  font-weight: 600;
  cursor: pointer;
}
.userUpdateItem {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}

.userUpdateItem > label {
  margin-bottom: 5px;
  font-size: 14px;
}

.images {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.image {
  margin: 1rem 0.5rem;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.image button {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
  border: none;
  color: white;
  background-color: lightcoral;
}

.image button:hover {
  background-color: red;
}

.image p {
  padding: 0 0.5rem;
  margin: 0;
}

.div_custom_Input {
  border: none;
  width: 70%;
  height: 30px;
  padding: 0px;
  text-align: center;
  font-size: 16px;
}

.upload-btn {
  cursor: pointer;
  display: block;
  margin: 0 auto;
  border: none;
  border-radius: 20px;
  width: 10rem;
  height: 3rem;
  color: white;
  background-color: green;
}

label:hover,
.upload-btn:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

.error {
  text-align: center;
}

.error span {
  color: red;
}

fieldset.group {
  margin: 0;
  padding: 0;
  margin-bottom: 1.25em;
  padding: 0.125em;
}

fieldset.group legend {
  margin: 0;
  padding: 0;
  font-weight: bold;
  margin-left: 20px;
  font-size: 100%;
  color: black;
}

ul.checkbox {
  margin: 0;
  padding: 0;
  margin-left: 20px;
  list-style: none;
}

ul.checkbox li input {
  margin-right: 0.25em;
}

ul.checkbox li {
  border: 1px transparent solid;
  display: inline-block;
  width: 30em;
}

ul.checkbox li:hover,
ul.checkbox li.focus {
  background-color: lightyellow;
  border: 1px gray solid;
  width: 12em;
}

.custom-minigrid-prd {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 4px;
  border: 1px solid #d7d8da;
  border-radius: 10px;
  width: 100%;
}

.custom-minigrid-prd td {
  text-align: center;
  vertical-align: middle;
}

.custom-minigrid-prd tbody td {
  border: 1px solid #d7d8da;
  border-radius: 10px;
  min-width: 40px;
  height: 40px;
}

.custom-minigrid-prd tbody td:not(:first-child) {
  padding: 5px;
  background-color: #f2f2f2;
}
.custom-minigrid-no-value {
  background-color: inherit !important;
}

.custom-minigrid-prd thead td {
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  vertical-align: middle;
}

@media (prefers-color-scheme: dark) {
  .custom-minigrid-prd {
    border: 1px solid #737373;
  }

  .custom-minigrid-prd tbody td:not(:first-child) {
    border-color: #737373;
    background-color: #949292;
  }
}

.sortable-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
}

.button_qtde {
  border: none;
  border-radius: 10px;
  padding: 5px 15px;
  background-color: #2dd36f;
  color: white;
  cursor: pointer;
  margin: 0px;
  margin-top: 10px;
  margin-right: 16px;
  width: 100px;
}

.containerCores {
  display: flex;
  flex-flow: wrap;
  height: 320;
  width: "70%";
  overflow: auto;
  border: "1px solid #d7d8da";
  border-radius: "10px";
  margin: "5px";
  gap: "0 30px";
}
.itemLIST {
  padding-top: "0px";
  padding-right: "0px";
  padding-bottom: "0px";
  font-size: "12px";
  width: auto;
}
.containerSizes {
  display: flex;
  flex-flow: wrap;
  height: 320;
  width: "30%";
  overflow: auto;
  border: "1px solid #d7d8da";
  border-radius: "10px";
  margin: "5px";
  gap: "0 30px";
}
