.right_black_List {
  font-size: 14px;
  font-weight: 700;
  color: black;
  line-height: 32px;
  text-align: left;
  justify-content: left;
}
.star {
  visibility: hidden;
  height: 20px;
  width: 20px;
  font-size: 30px;
  content: "\2606";
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 15px;
}
.star:before {
  content: "\2606";
  position: absolute;
  visibility: visible;
}
.star:checked:before {
  content: "\2605";
  position: absolute;
}
