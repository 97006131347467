.icon{
    margin-right: 5px;
    font-size: 20px !important;
    color: #EE8253

}

.subMenu {
    padding: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 10px;
    color:#EE8253;
    font-weight: 700;
    font-size: 13px;
  }
  .menu {
    color:#EE8253;
    font-weight: 700;
    font-size: 13px;
  }
  .label{
    display: block;
    text-align: center;
    color:#EE8253;
    font-weight: 500;
    font-size: 12px;
  }