.logoimg {
  width: 150px;
  align-items: center;
  justify-content: center;
}
.icon {
  padding-top: 10px;
}
.center {
  text-align: center;
  justify-content: center;
  border: 0px;
}

.divtitle {
  display: flex;
  padding-left: 0px;
  justify-content: left;
  /* border-top: 0.2px solid #666666;
  border-bottom: 0.2px solid #666666; */
}
.title {
  color: black;
  font-weight: 700;
  font-size: 20px;
  padding-left: 10px;
}

.right {
  text-align: right;
}
.tableBorder {
  border-bottom: 0px;
}
