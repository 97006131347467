.bannerUpload{
    max-width:400px;
    max-height:200px;
    width: auto;
    height: auto;
    border-radius: 10px;
    margin-right: 20px;
  }
  input.custom_Checkbox {
    width: 16px;
    height: 16px;
  }